import { isEmpty } from "common/Utils/formik";
import Field from "Components/Field";
import Button from "Components/Button";
import { WindowSize } from "Features/SendUsStory/SendUsStoryForm/SendUsStoryForm";
import Input from "Components/Input";
import Axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FC, useContext, useState, useEffect } from "react";
import {
  Info,
  Title,
  InputWrapper,
  LegalWrapper,
  InnerInputWrapper,
  EnquiryWrapper
} from "./AdvertiseWithUsForm.styled";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import TextArea from "Components/TextArea";
import { ButtonWrapper } from "Features/SendUsStory/SendUsStoryForm/SendUsStoryForm.styled";

const SignSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  address: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.number().required("Required"),
  enquiry: Yup.string().required("Required")
});

const AdvertiseWithUsForm: FC = () => {
  const { config } = useContext<publicationTypes>(PublicationContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [size, setSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
});

  if (success) {
    return <Title>Thank you! We received your request.</Title>;
  }

  const useWindowSize = (): WindowSize => {

    useEffect(() => {
      function handleResize(): void {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return (): void => window.removeEventListener("resize", 
    handleResize);
    }, []);
  
    return size;
  };
  useWindowSize();

  return (
    <>
      <Title>Contact us</Title>
      <Info></Info>
      <Formik
        initialValues={{
          name: "",
          company: "",
          address: "",
          email: "",
          phone: "",
          enquiry: "",
        }}
        onSubmit={async (values) => {
          try {
            await Axios.post(`/api/ses`, {
              from: "talktome@tindlenews.co.uk",
              to: "talktome@tindlenews.co.uk",
              body: `
                <body>
                  <h1>Publication user has sent advert request to: ${config.pub}</h1>
                  <br/>
                  <p>name:${values.name}</p>
                  <p>company:${values.company}</p>
                  <p>email: ${values.email}</p>
                  <p>address:${values.address}</p>
                  <p>phone: ${values.phone}</p>
                  <br />
                  <p>enquiry: ${values.enquiry}</p>
                </body>
              `,
              subject: `new advert for ${config.pub}`,
            });
            setSuccess(true);
          } catch (e) {
            console.log(e);
            setSuccess(true);
          }
        }}
        validationSchema={SignSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <InnerInputWrapper>
                <Input
                  placeholder="Name"
                  inputStyles="frosted"
                  label="Name"
                  name="name"
                  handleChange={handleChange}
                  required={true}
                  className="input-name"
                />
                <Field
                  placeholder="Company"
                  inputStyles="frosted"
                  label="Company"
                  handleChange={handleChange}
                  bottom_error={touched.company && errors.company}
                  required={true}
                  name="company"
                  className="input-company"
                />
              </InnerInputWrapper>
              <div>
                <Field
                  placeholder="Email"
                  inputStyles="frosted"
                  label="Email"
                  inputType="email"
                  required={true}
                  name="email"
                  bottom_error={touched.email && errors.email}
                  handleChange={handleChange}
                />
              </div>
            </InputWrapper>
            <EnquiryWrapper>
              <TextArea
                label="Enquiry"
                name="enquiry"
                placeholder="Insert your enquiry"
                handleChange={handleChange}
                type="form"
                required={true}
              />
            </EnquiryWrapper>
            <InputWrapper>
            <InnerInputWrapper>
                <Input
                  placeholder="Address"
                  inputStyles="frosted"
                  label="Address"
                  name="address"
                  handleChange={handleChange}
                  className="input-address"
                />
                <Field
                  placeholder="Phone Number"
                  inputStyles="frosted"
                  label="Phone number"
                  inputType="tel"
                  handleChange={handleChange}
                  bottom_error={touched.phone && errors.phone}
                  required={true}
                  name="phone"
                  pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
                  className="input-phone"
                />
               </InnerInputWrapper>
            </InputWrapper>
            <LegalWrapper>
              By submitting you confirm that you have read and agree to our{" "}
              <a href="/terms">Terms and Conditions</a> and{" "}
              <a href="/privacy">Privacy Policy</a>.
            </LegalWrapper>
            <ButtonWrapper>
            <Button
              disabled={
                isSubmitting ||
                isEmpty(errors) ||
                values.email === "" ||
                values.phone === "" ||
                values.address === "" ||
                values.name === "" ||
                values.enquiry === ""
              }
              buttonType="submit"
              buttonStyles="primary"
              fullwidth={size.width && size.width > 768 ? false : true}
            >
              Submit
            </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdvertiseWithUsForm;
